import React, { useState, useCallback } from "react";
import { useAuth } from '@clerk/clerk-react';
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import DomainsModalDNSTable from "./DomainsModalDNSTable.js";
import { useDomainVerification } from './DomainsVerificationContext';

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const styles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    },
    Modal: {
        maxWidth: '72rem'
    }
};

const DomainsModalDNS = ({ name, id, reload }) => {
    const [reloadKey, setReloadKey] = useState(0);
    const { getToken } = useAuth();
    const { verifyingDomains, retryVerification, updateDomainStatus } = useDomainVerification();
    
    const triggerReload = useCallback(() => {
        setReloadKey((prevKey) => prevKey + 1);
        if (reload) reload();
    }, [reload]);

    const handleVerification = async (closeModal) => {
        const token = await getToken();
        closeModal(); // Close the modal immediately when verification starts
        const success = await retryVerification(id, name, token);
        if (success) {
          updateDomainStatus(id, 'Verified');
          triggerReload();
        }
        closeModal();
      };

    const renderTitle = () => (
        <label style={styles.Title}>DNS Manager - {name}</label>
    );

    const renderBody = () => (
        <div key={reloadKey}>
            <DomainsModalDNSTable id={id} />
        </div>
    );

    const renderOptions = (closeModal) => (
        <>
            <Button 
                action={closeModal} 
                style={styles.CancelButton} 
                label="Close"
            />
            <Button
                action={() => handleVerification(closeModal)} 
                style={verifyingDomains[id] ? styles.DisabledAcceptButton : styles.AcceptButton} 
                label={verifyingDomains[id] ? "Verifying..." : "Verify"} 
                disabled={verifyingDomains[id]}
            />
        </>
    );

    const renderErrors = () => <div></div>;

    return (
        <Modal
            icon={"connection"}
            maxWidth={"max-w-2xl"}
            tooltip={"Connection"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
            styles={styles}
        />
    );
};

export default DomainsModalDNS;