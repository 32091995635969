import React from 'react';
import Table from '../parents/Table.js';
import Checkbox from '../parents/Checkbox.js';
import ConnectedIpsModalEdit from './ConnectedIpsModalEdit.js';
import ConnectedIpsModalDelete from './ConnectedIpsModalDelete.js';
import { Link } from 'react-router-dom';

const generateSlug = (text) => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')    // Replace spaces with -
    .replace(/&/g, '-and-')  // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-');  // Replace multiple - with single -
};

const ConnectedIpsIPsTable = ({ reload }) => {
    const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;
    const url = DJANGO_HOST + 'user_relay_server/';

    const headers = [
        { name: "IPS", available: true, key: "relay_server_name" }, // Ensure the key matches the row data
        { name: "Provider", available: true },
        { name: "Projects", available: true },
        { name: "Domains", available: true },
        { name: "Mailboxes", available: true },
        { name: "Sent Emails", available: true }
        // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints, Status, and Health from the headers
    ];

    const handlePrefixAction = (option, id) => {};

    const handleSuffixAction = (option, id) => {};

    const renderPrefix = (row) => {
        return (
            <td className="py-3 pl-6 text-left whitespace-nowrap">
                <Checkbox id={row.id}/>
            </td>
        );
    };

    const renderSuffix = (row) => {
        return (
            <td className="py-3 px-2 flex">
                <ConnectedIpsModalEdit name={row.relay_server_name} id={row.relay_server_id} reload={reload}></ConnectedIpsModalEdit>
                <ConnectedIpsModalDelete name={row.relay_server_name} id={row.relay_server_id} reload={reload}></ConnectedIpsModalDelete>
            </td>
        );
    };

    const renderIP = (cell, row) => {
        const slug = generateSlug(row.relay_server_name); // Generate slug dynamically
        return (
            <td className="py-3 px-6">
                <Link to={`/navigate/${slug}/`}>
                    {cell}
                </Link>
            </td>
        );
    };

    return (
        <Table
            url={url}
            title={"Connected IPs"}
            headers={headers}
            preffixColumn={null}
            suffixColumn={"Actions"}
            renderPrefix={renderPrefix}
            renderSuffix={renderSuffix}
            customRenderers={{ "relay_server_name": renderIP }} // Ensure the key matches the header key
            excludeFields={['id', 'status', 'health', 'open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}  // Exclude id, status, health, and other fields
        />
    );
};

export default ConnectedIpsIPsTable;
