import React, { useState, useEffect } from "react";
import { useAuth } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Input from "../parents/Input.js";
import Dropdown from "../parents/Dropdown.js";
import useCachedToken from '../../../useCachedToken'; // Import the custom hook

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    },
    Select: {
        width: '100%',
        padding: '0.5rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '1rem',
        boxSizing: 'border-box',
    }
}

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const ProjectsModalAdd = ({ reload }) => {
    const { relay_server_slug } = useParams(); // Get the relay_server_slug from the URL
    const [name, setName] = useState("");
    const [selectedIp, setSelectedIp] = useState('');
    const [ips, setIps] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = useCachedToken(); // Use the custom hook to get the token
    const { getToken } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            if (!token) return; // Exit if token is not available yet
            try {
                const response = await fetch(DJANGO_HOST + 'user_relay_server/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setIps(data.reverse()); // Reverse to ensure the latest IP is first

                if (relay_server_slug && data.length > 0) {
                    const ip = data.find(element => element.relay_server_name === relay_server_slug);
                    // setSelectedIp(ip ? ip.id : data[0].id);
                    setSelectedIp(ip ? ip.relay_server_id : data[0].relay_server_id);
                } else if (data.length > 0) {
                    setSelectedIp(data[0].relay_server_id);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [token, DJANGO_HOST, relay_server_slug]);

    const handleIPChange = (event) => {
        setSelectedIp(event.target.value);
    };

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Add a Project</label>
        );
    };

    const renderBody = () => {
        const ipOptions = ips.map(ip => ({ value: ip.relay_server_id, label: ip.relay_server_name }));
        // const ipOptions = ips.map(ip => ({ value: ip.id, label: ip.relay_server_name }));

        return (
            <>
                <p className="text-justify">
                    A project is a folder where you can group domains. You may use it to split teams, clients or objectives.
                </p>
                <br></br>
                <label className="font-semibold">IP</label>
                <div className="pb-4 pt-2">
                    <select value={selectedIp} onChange={handleIPChange} style={defaultStyles.Select} disabled={!!relay_server_slug}>
                        {ipOptions.map((ip) => (
                            <option key={ip.value} value={ip.value}>
                                {ip.label}
                            </option>
                        ))}
                    </select>
                </div>
                <label className="font-semibold">Project Name</label>
                <div className="pb-4 pt-2">
                    <Input name={"name"} placeholder={"Project Name"} onChange={(e) => setName(e.target.value)} />
                </div>
            </>
        );
    };

    const renderOptions = (closeModal) => {
        const action = async () => {
            // Before trying to do any action, verify the inputs
            const newErrors = [];

            if (!selectedIp) {
                newErrors.push('Please choose an IP');
            }

            if (!name) {
                newErrors.push('Please enter a name.');
            }

            if (newErrors.length > 0) {
                setErrors(newErrors);
                return;
            }

            // In case no errors are found, clean the previous ones
            setErrors([]);

            // If the information is properly set, then start the sending
            setLoading(true);

            const url = DJANGO_HOST + "projects/";

            try {
                const token = await getToken();

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify({
                        'name': name,
                        'relay_server': selectedIp
                    }),
                });

                const data = await response.json();
                console.log(data);

                if (response.status === 201) {
                    reload();
                    closeModal();
                } else {
                    console.error('Error in response:', data);
                    // Handle error response
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        return (
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Cancel"
                ></Button>
                <Button 
                    action={action} 
                    style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={loading ? "Processing..." : "Add project"} 
                    disabled={loading}
                ></Button>
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
            text={"Add Project"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default ProjectsModalAdd;
