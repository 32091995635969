import React from 'react';
import Table from '../parents/Table.js';

const ControlCenterMailboxesTable = (reloadKey) => {
  const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;
  
  const url = DJANGO_HOST + 'mailbox/';

  const headers = [
    { name: "Mailbox", available: true },
    { name: "Password", available: true },
    { name: "First Name", available: true },
    { name: "Last Name", available: true },
    { name: "Sent Emails", available: true },
    { name: "Health", available: false }
    // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints
  ];

  const handlePrefixAction = (option, id) => {};

  const handleSuffixAction = (option, id) => {};

  const renderPrefix = (row) => {};

  const renderSuffix = (row) => {};
  
  return (
    <Table
      url={url}
      title={"Mailboxes"}
      headers={headers}
      preffixColumn={false}
      suffixColumn={false}
      renderPrefix={renderPrefix}
      renderSuffix={renderSuffix}
      reloadKey={reloadKey}
      excludeFields={['open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}  // Exclude unwanted fields
    />
  );
};

export default ControlCenterMailboxesTable;
