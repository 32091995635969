import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState(null);
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const [showSubscriptionPrompt, setShowSubscriptionPrompt] = useState(false);
    const { getToken } = useAuth();
    const hasInitialized = useRef(false);

    const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

    useEffect(() => {
        console.log('Fetching user info');
        const fetchUserInfo = async () => {
            const token = await getToken();
            try {
                const response = await axios.get(DJANGO_HOST + 'api/userinfo/', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const user = response.data;
                console.log('User Info fetched:', user); // Debugging user info
                setUserInfo(user);

                if (!hasInitialized.current) {
                    hasInitialized.current = true;
                    await ensureChargebeeCustomer(user.email, user.first_name, user.last_name);
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        const ensureChargebeeCustomer = async (email, firstName, lastName) => {
            try {
                console.log('Ensuring Chargebee customer for:', email);
                const response = await axios.get(DJANGO_HOST + 'ensure_customer/', {
                    params: { email, first_name: firstName || "MissionInbox", last_name: lastName || "User" }
                });
                console.log('Chargebee customer ensured:', response.data); // Debugging Chargebee customer
                await fetchChargebeeInfo(email, firstName, lastName, response.data.customer_id);
            } catch (error) {
                console.error('Error ensuring Chargebee customer:', error);
            }
        };

        const fetchChargebeeInfo = async (email, firstName, lastName, customerId) => {
            try {
                console.log('Fetching Chargebee subscription info for:', email);
                const response = await axios.get(DJANGO_HOST + 'get_user_subscriptions/', {
                    params: { email }
                });
                console.log('Chargebee subscription response:', response.data); // Debugging Chargebee subscription response
                
                const subscriptions = response.data.subscriptions.map(subscription => ({
                    ...subscription,
                    billing_cycle: subscription.billing_cycle || 'Monthly',
                    customer_id: subscription.customer_id,
                    subscription_id: subscription.subscription_id,
                    payment_method_present: subscription.payment_method_present
                }));
                console.log('Parsed Subscriptions:', subscriptions); // Debugging subscriptions

                setSubscriptionDetails(subscriptions);

                const baseSubscription = subscriptions.find(sub => sub.plan_name === "Base-Subscription-USD-Monthly");
                console.log('Base Subscription:', baseSubscription); // Debugging base subscription

                if (!baseSubscription) {
                    console.log('Base subscription not found, registering user to Base-Subscription-USD-Monthly.');
                    await registerBaseSubscription(customerId);
                }

                if (baseSubscription && (baseSubscription.status === "cancelled" || 
                    (baseSubscription.status === "active" && !baseSubscription.payment_method_present))) {
                    setShowSubscriptionPrompt(true);
                } else {
                    setShowSubscriptionPrompt(false);
                }
            } catch (error) {
                console.error('Error fetching Chargebee subscription info:', error);
            }
        };

        const registerBaseSubscription = async (customerId) => {
            try {
                console.log('Registering user to Base-Subscription-USD-Monthly...');
                const response = await axios.post(DJANGO_HOST + 'subscribe_base_plan/', {
                    customer_id: customerId,
                    plan_name: 'Base-Subscription-USD-Monthly',
                    billing_cycle: 'Monthly'
                });
                window.location.reload()
                console.log('Base subscription registered:', response.data); // Debugging subscription registration
                // After registering, refetch the subscription info to update the UI
                await fetchChargebeeInfo(userInfo.email, userInfo.first_name, userInfo.last_name, customerId);
            } catch (error) {
                console.error('Error registering base subscription:', error);
            }
        };

        fetchUserInfo();
    }, [getToken]);

    const renderSubscriptionPrompt = () => {
        const baseSubscription = subscriptionDetails.find(sub => sub.plan_name === "Base-Subscription-USD-Monthly");

        if (!showSubscriptionPrompt || !baseSubscription) return null;

        const handleAddPaymentMethod = async () => {
            try {
                console.log('Creating payment method page for:', baseSubscription.customer_id); // Debugging add payment
                const response = await axios.get(DJANGO_HOST + 'create_update_payment_method_page/', {
                    params: { customer_id: baseSubscription.customer_id }
                });
                console.log('Payment Method Page URL:', response.data.url); // Debugging payment URL
                if (response.data.url) {
                    window.location.href = response.data.url;
                }
            } catch (error) {
                console.error('Error creating update payment method page:', error);
            }
        };

        const handleRenewSubscription = async () => {
            try {
                console.log('Renewing subscription for:', baseSubscription.customer_id); // Debugging renewal
                const response = await axios.post(DJANGO_HOST + 'renew_subscription/', {
                    customer_id: baseSubscription.customer_id,
                    subscription_id: baseSubscription.subscription_id
                });
                console.log('Renew Subscription Response:', response.data); // Debugging renewal response
                if (response.data.message === "Subscription renewed successfully") {
                    window.location.href = '/ControlCenter';
                }
            } catch (error) {
                console.error('Error renewing subscription:', error);
            }
        };

        const handleButtonClick = async () => {
            if (!baseSubscription.payment_method_present) {
                await handleAddPaymentMethod();
            } else {
                await handleRenewSubscription();
            }
        };

        return (
            <div style={overlayStyle}>
                <div style={promptStyle}>
                    <h2 style={headerStyle}>
                        {baseSubscription.status === 'cancelled' ? 'Your subscription has ended.' : 'Payment Method Required'}
                    </h2>
                    <p style={textStyle}>
                        {baseSubscription.status === 'cancelled' 
                            ? 'Please renew to continue enjoying our services.'
                            : 'Please add a payment method to continue enjoying our services.'}
                        {!baseSubscription.payment_method_present && baseSubscription.status === 'cancelled' && 
                            ' You will be redirected to add a payment method after renewal.'}
                    </p>
                    <button style={buttonStyle} onClick={handleButtonClick}>
                        {baseSubscription.status === 'cancelled' ? 'Renew Subscription' : 'Add Payment Method'}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <UserContext.Provider value={{
            userInfo,
            subscriptionDetails,
            showSubscriptionPrompt,
            setShowSubscriptionPrompt,
        }}>
            {renderSubscriptionPrompt()}
            {children}
        </UserContext.Provider>
    );
};

const overlayStyle = {
    position: 'absolute',
    top: '0',
    left: '225px',  // Start from the right edge of the sidebar
    width: 'calc(100% - 225px)',  // Cover the rest of the content area
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const promptStyle = {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '12px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.25)',
    textAlign: 'center',
    maxWidth: '400px',
    width: '100%',
};

const headerStyle = {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '16px',
};

const textStyle = {
    fontSize: '16px',
    marginBottom: '24px',
    lineHeight: '1.5',
};

const buttonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
};

export const useUser = () => useContext(UserContext);
