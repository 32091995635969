import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';
import { useUser } from '../../usercontext';

const UserInfo = ({ isCollapsed }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { subscriptionDetails, isLoading, setShowSubscriptionPrompt, showSubscriptionPrompt } = useUser();
  const { getToken } = useAuth();
  const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;
  const url = DJANGO_HOST + 'api/userinfo/';

  useEffect(() => {
    if (!isCollapsed) {
      const fetchData = async () => {
        try {
          const token = await getToken();
          const response = await axios.get(url, {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': `Bearer ${token}`
            }
          });
          setUserInfo(response.data);

          // Incorporating subscription logic
          const baseSubscription = subscriptionDetails.find(
            (subscription) => subscription.plan_name === 'Base-Subscription-USD-Monthly'
          );
          if (baseSubscription) {
            if (
              baseSubscription.status === 'cancelled' ||
              (baseSubscription.status === 'active' &&
                !baseSubscription.payment_method_present)
            ) {
              setShowSubscriptionPrompt(true);
            } else {
              setShowSubscriptionPrompt(false);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [isCollapsed, url, getToken, subscriptionDetails, setShowSubscriptionPrompt]);

  if (isCollapsed || isLoading || !userInfo) {
    return null;
  }

  const renderSubscriptionStatus = () => {
    const baseSubscription = subscriptionDetails.find(
      (subscription) => subscription.plan_name === 'Base-Subscription-USD-Monthly'
    );
    if (!baseSubscription) return null;

    let statusColor;
    let statusText;

    switch (baseSubscription.status) {
      case 'active':
        statusColor = 'border-green-500';
        statusText = 'Active';
        break;
      case 'in_trial':
        statusColor = 'border-yellow-500';
        statusText = 'Trial';
        break;
      case 'cancelled':
        statusColor = 'border-red-500';
        statusText = 'Cancelled';
        break;
      case 'non_renewing':
        statusColor = 'border-blue-500';
        statusText = 'Non-Renewing';
        break;
      default:
        statusColor = 'border-gray-500';
        statusText = 'Unknown';
        break;
    }

    const displayName = userInfo
      ? (userInfo.name ||
         (userInfo.first_name || userInfo.last_name
           ? `${userInfo.first_name || ''} ${userInfo.last_name || ''}`.trim()
           : 'User'))
      : '';

    const shouldShowTooltip = !userInfo.name && (!userInfo.first_name && !userInfo.last_name);

    return (
      <div className={`border-4 ${statusColor} rounded-lg p-2 relative`}>
        <span
          className={`absolute top-0 left-0 text-xs ${statusColor.replace(
            'border',
            'bg'
          )} text-white py-1 px-2 rounded-tl-lg rounded-br-lg z-10`}
          style={{ transform: 'translate(-1px, -1px)' }}
        >
          {statusText}
        </span>
        
        {userInfo ? (
          <>
            <div 
              className="mt-6 text-sm font-bold text-white break-words relative"
              onMouseEnter={() => shouldShowTooltip && setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              {displayName}
              {showTooltip && shouldShowTooltip && (
                <div className="absolute left-0 bottom-full mb-2 p-2 bg-gray-800 text-white text-xs rounded shadow-lg">
                  Configure your name in account settings
                </div>
              )}
            </div>
            <div className="text-sm text-white break-words">
              {userInfo.email}
            </div>
          </>
        ) : (
          <div className="text-sm text-white">Loading user information...</div>
        )}
      </div>
    );
  };

  return (
    <div className="p-4 text-white">
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <p>IPs:</p>
          <p className="font-semibold">{userInfo.ips}</p>
        </div>
        <div>
          <p>Projects:</p>
          <p className="font-semibold">{userInfo.projects}</p>
        </div>
        <div>
          <p>Domains:</p>
          <p className="font-semibold">{userInfo.domains}</p>
        </div>
        <div>
          <p>Mailboxes:</p>
          <p className="font-semibold">{userInfo.mailboxes}</p>
        </div>
      </div>
      {renderSubscriptionStatus()}
    </div>
  );
};

export default UserInfo;