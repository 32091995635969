import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../parents/Table.js';
import MailboxesModalConnect from './MailboxesModalConnect.js';
import MailboxesModalDelete from './MailboxesModalDelete.js';
import MailboxesModalDisable from './MailboxesModalDisable.js';
import MailboxesModalEdit from './MailboxesModalEdit.js';
import useCachedToken from '../../../useCachedToken'; // Import the custom hook

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const MailboxesTable = ({ reload }) => {
    const { relay_server_slug, project_slug, domain_slug } = useParams(); // Get the slugs from the URL
    const [mailboxes, setMailboxes] = useState([]);
    const token = useCachedToken(); // Get the token using the custom hook

    const url = relay_server_slug && project_slug && domain_slug
        ? `${DJANGO_HOST}mailbox/?relay_server_slug=${relay_server_slug}&project_slug=${project_slug}&domain_slug=${domain_slug}`
        : `${DJANGO_HOST}mailbox/`;

    const headers = [
        { name: "Mailbox", available: true },
        { name: "Password", available: true },
        { name: "First Name", available: true },
        { name: "Last Name", available: true },
        { name: "Sent Emails", available: true },
        { name: "Health", available: false },
        { name: "Domain", available: true }
        // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints
    ];

    useEffect(() => {
        const fetchMailboxes = async () => {
            try {
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}` // Add the token to the request headers
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setMailboxes(data);
            } catch (error) {
                console.error('Error fetching mailboxes:', error);
            }
        };

        if (token) {
            fetchMailboxes();
        }
    }, [url, token]);

    const handlePrefixAction = (option, id) => {};

    const handleSuffixAction = (option, id) => {};

    const renderPrefix = (row) => {
        return (
            <td className="py-3 px-6 flex">
                <MailboxesModalDisable isChecked={row.active}></MailboxesModalDisable>
            </td>
        );
    };

    const renderSuffix = (row) => {
        return (
            <td className="py-3 px-2 flex">
                <MailboxesModalConnect mailboxData={row} />
                <MailboxesModalEdit mailboxData={row} reload={reload}></MailboxesModalEdit>
                <MailboxesModalDelete id={row.id} name={row.first_name} reload={reload} />
            </td>
        );
    };

    return (
        <Table
            url={url}
            headers={headers}
            title={"Mailboxes"}
            preffixColumn={null}
            suffixColumn={"Actions"}
            renderPrefix={renderPrefix}
            renderSuffix={renderSuffix}
            handlePrefixAction={handlePrefixAction}
            handleSuffixAction={handleSuffixAction}
            data={mailboxes} // Pass the fetched mailboxes data
            excludeFields={['open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}  // Exclude unwanted fields
        />
    );
};

export default MailboxesTable;
