import React, { useState, useEffect } from "react";
import { useAuth } from '@clerk/clerk-react';
import Input from "../parents/Input.js";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Dropdown from "../parents/Dropdown.js";
import useCachedToken from '../../../useCachedToken.js';
import { toast } from 'react-hot-toast';


const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
};

const DomainsModalAdd = ({ reload }) => {
    const [name, setName] = useState("");
    const [selectedIp, setSelectedIp] = useState("");
    const [ips, setIps] = useState({});
    const [selectedProject, setSelectedProject] = useState("");
    const [projects, setProjects] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { getToken } = useAuth();
    const token = useCachedToken();
  
    useEffect(() => {
      const fetchData = async () => {
        const token = await getToken();
        if (!token) return;
  
        try {
          const response = await fetch(DJANGO_HOST + 'user_relay_server/', {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();
          const ipsData = {};
          data.forEach(element => {
            ipsData[element.relay_server_name] = element;
          });
          setIps(ipsData);
        } catch (error) {
          console.error('Error fetching IPs:', error);
        }
  
        try {
          const response = await fetch(DJANGO_HOST + 'projects/', {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = await response.json();
          const projectsData = {};
          data.forEach(element => {
            projectsData[element.name] = element.id;
          });
          setProjects(projectsData);
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      };
      fetchData();
    }, [getToken]);
  
    const handleIpChange = (e) => {
      const ipName = e.target.value;
      setSelectedIp(ipName);
    };
  
    const renderTitle = () => {
      return (
        <label style={defaultStyles.Title}>Add Domain</label>
      );
    };
  
    const renderBody = () => {
      return (
        <>
          <p className="text-justify">
            Add your domains to Mission Inbox. This way, you can benefit from our high-volume mailboxes with your mature domains.
          </p>
          <div className="pb-4">
            <label className="font-semibold">IP</label>
            <select
              value={selectedIp}
              onChange={handleIpChange}
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="" disabled hidden>
                Select an IP
              </option>
              {Object.keys(ips).map((ipName, index) => (
                <option key={index} value={ipName}>
                  {ipName}
                </option>
              ))}
            </select>
          </div>
          <div className="pb-4">
            <label className="font-semibold">Project</label>
            <Dropdown
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
              label={"Select a Project"}
              values={projects}
            />
          </div>
          <div className="pb-4">
            <label className="font-semibold">Domain</label>
            <Input name={"name"} placeholder={"Name"} onChange={(e) => setName(e.target.value)} />
          </div>
        </>
      );
    };
  
    const renderOptions = (closeModal) => {
      const action = async () => {
        const newErrors = [];
  
        if (!selectedIp) {
          newErrors.push("Please select an IP");
        }
        if (!selectedProject) {
          newErrors.push("Please select a Project");
        }
        if (!name) {
          newErrors.push("Domain cannot be empty");
        }
  
        if (newErrors.length > 0) {
          setErrors(newErrors);
          return;
        }
  
        setErrors([]);
        setLoading(true);
  
        const url = DJANGO_HOST + "domain/";
  
        try {
          const token = await getToken();
          const payload = {
            'name': name,
            'relay_server': ips[selectedIp].relay_server_id,
            'project': selectedProject
          };
  
          console.log('Sending payload:', payload);
  
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'X-Requested-With': 'XMLHttpRequest',
            },
            body: JSON.stringify(payload),
          });
  
          const responseText = await response.text();
  
          if (response.status === 201) {
            console.log('Domain added successfully');
            toast.success('Domain added successfully!');
            closeModal();
            reload(); // Call the reload function after successful addition
          } else if (response.status === 400) {
            console.error('Error in response:', responseText);
            toast.error('Cannot add this domain . It already exists in our system.');
          } else {
            console.error('Error in response:', responseText);
            toast.error('An error occurred while adding the domain.');
          }
        } catch (error) {
          console.error('Error adding domain:', error);
          toast.error('An unexpected error occurred. Please try again later.');
        } finally {
          setLoading(false);
        }
      };
      return (
        <>
          <Button
            action={closeModal}
            style={defaultStyles.CancelButton}
            label="Cancel"
          />
          <Button
            action={action}
            style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton}
            label={loading ? "Processing..." : "Add Domain"}
            disabled={loading}
          />
        </>
      );
    };
  
    const renderErrors = () => {
      return (
        <ul>
          {errors.map((error, index) => (
            <li key={index} style={{ color: 'red' }}>
              - {error}
            </li>
          ))}
        </ul>
      );
    };
  
    return (
      <Modal
        text={"Add Domain"}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
      />
    );
  };
  
  export default DomainsModalAdd;