import { useState, useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';

// Custom hook to manage token caching
const useCachedToken = () => {
  const { getToken } = useAuth();

  // Initialize state with cached token and expiry from localStorage
  const [cachedToken, setCachedToken] = useState(() => {
    const savedToken = localStorage.getItem('token');
    const savedExpiry = localStorage.getItem('tokenExpiry');
    if (savedToken && savedExpiry && new Date(savedExpiry) > new Date()) {
      return { token: savedToken, expiry: new Date(savedExpiry) };
    }
    return null;
  });

  // Function to fetch a new token and update cache
  const fetchToken = async () => {
    const newToken = await getToken();
    const decodedToken = JSON.parse(atob(newToken.split('.')[1]));
    const expiry = new Date(decodedToken.exp * 1000);

    localStorage.setItem('token', newToken);
    localStorage.setItem('tokenExpiry', expiry);

    setCachedToken({ token: newToken, expiry });
  };

  // Effect to fetch token if not available or expired
  useEffect(() => {
    if (!cachedToken || cachedToken.expiry <= new Date()) {
      fetchToken();
    }
  }, [cachedToken]);

  return cachedToken?.token;
};

export default useCachedToken;
