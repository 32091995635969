import React, { useEffect, useState } from 'react';
import Table from '../parents/Table';
import { useAuth } from '@clerk/clerk-react';

const AlertsTable = ({ setError }) => {
  const { getToken } = useAuth();
  const [data, setData] = useState([]);
  const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;
  const API_URL = DJANGO_HOST + 'api/alerts/';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getToken();
        if (!token) {
          throw new Error('No token retrieved');
        }

        const response = await fetch(API_URL, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${token}`
          }
        });

        const text = await response.text();
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = JSON.parse(text);
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('');
      }
    };

    fetchData();
  }, [API_URL, getToken, setError]);

  if (data.length === 0) {
    return <div></div>;
  }

  const headers = [
    "User",
    "Message",
    "Impact",
    "Relay Server",
    "Project",
    "Domain",
    "Mailbox"
  ];

  const renderPrefix = (row) => {
    return (
      <td className="py-3 px-6 text-left whitespace-nowrap">
        {row.impact === 'low' && (
          <svg xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke="green" 
              className="w-6 h-6">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
          </svg>
        )}
        {row.impact === 'medium' && (
          <svg xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke="orange" 
              className="w-6 h-6">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
          </svg>
        )}
        {row.impact === 'high' && (
          <svg xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke="red" 
              className="w-6 h-6">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>
        )}
        {row.impact === 'critical' && (
          <svg xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24" 
              strokeWidth="1.5" 
              stroke="darkred" 
              className="w-6 h-6">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>
        )}
      </td>
    );
  };

  const renderSuffix = (row) => {};

  const rows = data.map(alert => ({
    id: alert.id,
    user: alert.user,
    message: alert.message,
    impact: alert.impact,
    relay_server: alert.relay_server || 'N/A',
    project: alert.project || 'N/A',
    domain: alert.domain || 'N/A',
    mailbox: alert.mailbox || 'N/A'
  }));

  return (
    <Table
      data={rows}
      headers={headers}
      preffixColumn={true}
      suffixColumn={false}
      renderPrefix={renderPrefix}
      renderSuffix={renderSuffix}
    />
  );
};

export default AlertsTable;
