import React, { useState } from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Input from "../parents/Input.js";
import { useAuth } from '@clerk/clerk-react';

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    },
    BodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    Section: {
        // Add your styles here
    },
    SectionTitle: {
        fontWeight: 'bold',
    },
    InputGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
    },
    PasswordGroup: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    InputBox: {
        // Add your styles here
    },
    ShowHideButton: {
        // Add your styles here
    },
    ResetButton: {
        // Add your styles here
    },
    ErrorList: {
        // Add your styles here
    },
    ErrorItem: {
        color: 'red',
    },
}

const MailboxesModalEdit = ({ mailboxData, reload}) => {
    const [errors, setErrors] = useState([]);
    const [firstName, setFirstName] = useState(mailboxData.first_name);
    const [lastName, setLastName] = useState(mailboxData.last_name);
    const [mailboxName, setMailboxName] = useState(mailboxData.email);
    const [password, setPassword] = useState(mailboxData.password);
    const [autoReply, setAutoReply] = useState('');
    const [isPasswordEditable, setIsPasswordEditable] = useState(false);
    const { getToken } = useAuth();
    const [newPassword, setNewPassword] = useState('');


const resetPassword = async () => {
    try {
        const url = `${DJANGO_HOST}mailbox/${mailboxData.id}/`;
        const token = await getToken();
        const response = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: JSON.stringify({ password: newPassword }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setPassword(data.password); 
        setIsPasswordEditable(false); 
        setNewPassword(''); 
        reload();
    } catch (error) {
        setErrors([...errors, 'Failed to reset password']);
        console.error('Error resetting password:', error);
    }
};

    const handleSave = async () => {
        const payload = {
            first_name: firstName,
            last_name: lastName,
            email: mailboxName,
            password,
            auto_reply: autoReply,
        };

        try {
            const url = DJANGO_HOST + "mailbox/" + mailboxData.id + "/";

            const token = await getToken();
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log('Mailbox updated successfully');
            reload();
        } catch (error) {
            setErrors([...errors, 'Failed to update mailbox']);
            console.error('Error updating mailbox:', error);
        }
    };

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Mailbox Configuration</label>
        );
    };

    const renderSubtitle = () => {
        return (
            <p style={defaultStyles.Subtitle}>Edit the sender information and autoreply setup</p>
        );
    };

    const renderBody = () => {
        return (
            <div style={defaultStyles.BodyContainer}>
                {renderSubtitle()}
                <section style={defaultStyles.Section}>
                    <h3 style={defaultStyles.SectionTitle}>Sender Information</h3>
                    <div style={defaultStyles.InputGroup}>
                        <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" style={defaultStyles.InputBox} />
                        <Input value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" style={defaultStyles.InputBox} />
                    </div>
                </section>
                <section style={defaultStyles.Section}>
                    <h3 style={defaultStyles.SectionTitle}>Mailbox Information</h3>
                    <div style={defaultStyles.InputGroup}>
                        <p>Mailbox: {mailboxName}</p>
                    </div>
                    <div style={defaultStyles.PasswordGroup}>
                        <p>Password</p>
                        {isPasswordEditable ? (
                            <Input
                                type="text"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Enter new password"
                                style={defaultStyles.InputBox}
                            />
                        ) : (
                            <div onClick={() => setIsPasswordEditable(true)} style={{ ...defaultStyles.InputBox, cursor: 'pointer' }}>
                                {password}
                            </div>
                        )}
                        <Button
                            action={resetPassword}
                            label="Reset Password"
                        />
                    </div>
                </section>
                {/* <section style={defaultStyles.Section}>
                    <h3 style={defaultStyles.SectionTitle}>Auto Reply to (Optional)</h3>
                    <Input value={autoReply} onChange={(e) => setAutoReply(e.target.value)} placeholder="Enter the mailbox every reply should be sent to" style={defaultStyles.InputBox} />
                </section> */}
            </div>
        );
    };

    const renderOptions = (closeModal) => {
        return (
            <>
                <Button action={closeModal} style={defaultStyles.CancelButton} label="Cancel" />
                <Button action={handleSave} style={defaultStyles.AcceptButton} label="Save" />
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul style={defaultStyles.ErrorList}>
                {errors.map((error, index) => (
                    <li key={index} style={defaultStyles.ErrorItem}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
            icon={"edit"}
            tooltip={"Edit"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default MailboxesModalEdit;
