import React, { useState } from "react";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { useUser } from '../../../usercontext';

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}
const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const MailboxesModalDelete = ({ id, name, reload, totalMailboxes }) => {
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getToken } = useAuth();
    const { userInfo, subscriptionDetails } = useUser();


    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Are you sure you want to delete - {name}?</label>
        );
    };

    const renderBody = () => {
        return (
            <>
                <p className="text-justify">
                    By deleting this mailbox, you will not be able to send emails through it anymore.
                </p>
            </>
        );
    };

    const updateMailboxCountOnChargebee = async () => {
        if (!userInfo || !subscriptionDetails) {
            alert('User info or subscriptions are not loaded yet.');
            return;
        }

        const baseSubscription = subscriptionDetails.find(sub => sub.plan_name === 'Base-Subscription-USD-Monthly');
        if (!baseSubscription) {
            alert('Base Subscription not found.');
            return;
        }

        const customer_id = baseSubscription.customer_id;
        const addon_id = 'Mailbox-USD-Monthly';
        const quantity = baseSubscription.total_mailboxes - 1; // Decrease by 1 on deletion

        if (!customer_id) {
            alert('Customer ID not found.');
            return;
        }

        try {
            const csrfToken = getCookie('csrftoken');
            const token = await getToken();

            const response = await axios.post(`${DJANGO_HOST}update_mailbox_addon/`, {
                customer_id,
                addon_id,
                quantity,
                prorate: true, // Apply prorate for Chargebee
            }, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (response.data.message) {
                alert('Mailbox count updated successfully');
            } else {
                alert('Failed to update mailbox count');
            }
        } catch (error) {
            console.error('Error updating mailbox count:', error);
            if (error.response && error.response.data) {
                alert(`Error updating mailbox count: ${error.response.data.error}`);
            } else {
                alert('Error updating mailbox count');
            }
        }
    };
    

    const handleDelete = async (closeModal) => {
        const url = `${DJANGO_HOST}mailbox/${id}/`;
    
        setLoading(true);
        try {
            const token = await getToken();
    
            console.log("Deleting mailbox with id:", id);
    
            // Delete the mailbox from the database
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-Requested-With': 'XMLHttpRequest',
                }
            });
    
            if (response.status === 204) {
                console.log("Mailbox deleted successfully, updating Chargebee...");
                await updateMailboxCountOnChargebee();
                closeModal();  // Close the modal first
                window.location.reload();  // Trigger a full page reload
            } else {
                const errorData = await response.json();
                setErrors([errorData.message || "Failed to delete mailbox"]);
            }
        } catch (error) {
            console.error('Error deleting mailbox:', error);
            setErrors([error.message || "An error occurred while deleting mailbox."]);
        } finally {
            setLoading(false);
        }
    };

    // Helper function to get a cookie by name
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const renderOptions = (closeModal) => {
        return(
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Close"
                ></Button>
                <Button 
                    action={() => handleDelete(closeModal)} 
                    style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={loading ? "Processing..." : "Yes, Delete it"} 
                    disabled={loading}
                ></Button>
            </>
        );
    };
    
    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
        icon={"delete"}
        tooltip={"Delete"}
        renderTitle={renderTitle}
        renderBody={renderBody}
        renderOptions={renderOptions}
        renderErrors={renderErrors}
        />
    );
};

export default MailboxesModalDelete;
