import React from 'react';
import Table from '../parents/Table.js';

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const ControlCenterProjectsTable = (reloadKey) => {
  const url = DJANGO_HOST + 'projects/';

  const headers = [
    { name: "Project", available: true },
    { name: "Domains", available: true },
    { name: "Mailboxes", available: true },
    { name: "Sent Emails", available: true }
    // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints, Status, and Health from the headers
  ];

  const handlePrefixAction = (option, id) => {};
  const handleSuffixAction = (option, id) => {};
  const renderPrefix = (row) => {};
  const renderSuffix = (row) => {};

  return (
    <Table
      url={url}
      title={"Projects"}
      headers={headers}
      preffixColumn={false}
      suffixColumn={false}
      renderPrefix={renderPrefix}
      renderSuffix={renderSuffix}
      reloadKey={reloadKey}
      excludeFields={['open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints', 'status', 'health']}  // Exclude unwanted fields
    />
  );
};

export default ControlCenterProjectsTable;
