import React, { useState, useEffect, useCallback } from 'react';
import MailboxesTable from "./components/Mailboxes/MailboxesTable.js";
import MailboxesCard from "./components/Mailboxes/MailboxesCard.js";
import MailboxesModalAdd from './components/Mailboxes/MailboxesModalAdd.js';
import MailboxesModalGet from './components/Mailboxes/MailboxesModalBuy.js';
import Dropdown from './components/parents/Dropdown.js';
import { useAuth } from '@clerk/clerk-react';

const Mailboxes = () => {
  const [reloadKey, setReloadKey] = useState(0);
  const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;
  const { getToken } = useAuth();

  const handleDropdownChange = async (event) => {
    const value = event.target.value;
    const id = "your-mailbox-id"; 
    let url = '';

    if (value === 'instantly') {
      url = `${DJANGO_HOST}mailbox/download/instantly/${id}/`;
    } else if (value === 'smartlead') {
      url = `${DJANGO_HOST}mailbox/download/smartlead/${id}/`;
    }

    try {
      const token = await getToken();

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Requested-With': 'XMLHttpRequest',
        }
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = value === 'instantly' ? 'instantly_file.csv' : 'smartlead_file.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(downloadUrl);
      } else {
        console.error('Error downloading file:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const customDropdownStyle = {
    cursor: 'pointer',
    padding: '2px 8px',
    margin: '0px 4px',
    border: '0px',
    boxSizing: 'border-box',
    borderRadius: '6px',
    backgroundColor: 'rgb(57, 217, 211)',
    color: 'rgb(0, 0, 0)',
    fontWeight: 700,
    lineHeight: '20px',
    outline: 'none',
  };

  const dropdownValues = {
    'Instanly': 'instantly',
    'SmartLead': 'smartlead',
  };

  const triggerReload = useCallback(() => {
    setReloadKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <div key={reloadKey}>
      <nav className="navbar flex justify-between items-center p-4">
        <label className='page-header text-lg font-bold'>Mailboxes</label>
        <div className="relative inline-block w-32">
          <Dropdown
            value=""
            onChange={handleDropdownChange}
            label="Bulk Export"
            values={dropdownValues}
            style={customDropdownStyle}
          />
        </div>
      </nav>
      <div className="flex w-full justify-end p-4">
        <MailboxesModalAdd reload={triggerReload}/>
      </div>
      <div className="px-10 py-5">
        <MailboxesTable reload={triggerReload}></MailboxesTable>
      </div>
    </div>
  );
}

export default Mailboxes;