import React, { useEffect, useState, useCallback } from 'react';

import { Link } from 'react-router-dom';

import { useAuth } from '@clerk/clerk-react';

import ConnectedIpsCard from './components/ConnectedIPs/ConnectedIpsCard.js';
import ConnectedIpsModalIpForSales from './components/ConnectedIPs/ConnectedIpsModalIpForSales.js';
import ConnectedIpsModalAddIp from './components/ConnectedIPs/ConnectedIpsModalAddIp.js';
import ConnectedIpsIPsTable from './components/ConnectedIPs/ConnectedIpsIPsTable.js';
import WeekAreaChart from './components/ConnectedIPs/ConnectedIpsChart.js';


const ConnectedIps = () => {
  const { getToken } = useAuth();
  const [cardData, setCardData] = useState([]);
  const [reloadKey, setReloadKey] = useState(0);

  const triggerReload = useCallback(() => {
      setReloadKey((prevKey) => prevKey + 1);
  }, []);
  

  return (
    <div key={reloadKey}>
      <label className='page-header'>Connected IPs</label>
      <div className="px-10 py-5">
        <div className="flex justify-end pb-5">
          {/*<h1 className="font-bold">IPs</h1>*/}
          <div className='flex'>
            <ConnectedIpsModalIpForSales reload={triggerReload}/>
            <ConnectedIpsModalAddIp reload={triggerReload} />
          </div>
        </div>
        <ConnectedIpsIPsTable reload={triggerReload}/>
      </div>
    </div>
  );
}

export default ConnectedIps;