import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Table from '../parents/Table.js';
import ProjectsModalDisable from './ProjectsModalDisable.js';
import ProjectsModalDelete from './ProjectsModalDelete.js';
import ProjectsModalEdit from './ProjectsModalEdit.js';
import useCachedToken from '../../../useCachedToken'; // Import the custom hook
import 'tippy.js/dist/tippy.css';

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const generateSlug = (text) => {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')    // Replace spaces with -
        .replace(/&/g, '-and-')  // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-');  // Replace multiple - with single -
};

const ProjectsTable = ({ reload }) => {
    const { relay_server_slug } = useParams(); // Get the relay_server_slug from the URL
    const [projects, setProjects] = useState([]); // Initialize projects as an empty array
    const token = useCachedToken(); // Get the token using the custom hook
    const url = relay_server_slug 
        ? `${DJANGO_HOST}projects/?relay_server_slug=${relay_server_slug}` 
        : `${DJANGO_HOST}projects/`;

    const headers = [
        { name: "Project", available: true },
        { name: "Domains", available: true },
        { name: "Mailboxes", available: true },
        { name: "Sent Emails", available: true }
        // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints, Status, and Health from the headers
    ];

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                // Ensure data is an array
                setProjects(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        if (token) {
            fetchProjects();
        }
    }, [url, relay_server_slug, token]);

    const handlePrefixAction = (option, id) => {};

    const handleSuffixAction = (option, id) => {};

    const renderProjectLink = (cell, row) => {
        const projectSlug = generateSlug(row.name);
        const relayServerSlug = row.relay_server_slug; // Use the serialized slug directly

        if (!relayServerSlug) {
            return <td className="py-3 px-6">{cell}</td>; // If relayServerSlug is missing, just render the cell content
        }

        return (
            <td className="py-3 px-6 whitespace-normal break-words">
                <Link to={`/navigate/${relayServerSlug}/${projectSlug}/`}>
                    {cell}
                </Link>
            </td>
        );
    };

    const renderSuffix = (row) => {
        return (
            <td className="py-3 px-2 flex whitespace-normal break-words">
                {/* You can re-enable modal edit/delete here if needed */}
                {/* <ProjectsModalEdit name={row.name} id={row.id} reload={reload}></ProjectsModalEdit>
                <ProjectsModalDelete name={row.name} id={row.id} reload={reload}></ProjectsModalDelete> */}
            </td>
        );
    };

    return (
        <Table
            url={url}
            headers={headers}
            title={"Projects"}
            data={projects} // Pass projects data without modifications
            preffixColumn={null}
            suffixColumn={"Actions"}
            renderPrefix={null}
            renderSuffix={renderSuffix}
            customRenderers={{ "name": renderProjectLink }} // Ensure the key matches the header key
            handlePrefixAction={() => {}}
            handleSuffixAction={() => {}}
            excludeFields={['status', 'health', 'open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}  // Exclude unnecessary fields
        />
    );
};

export default ProjectsTable;
