import React, { useState, useEffect, useCallback } from 'react';
import ControlCenterCard from './components/ControlCenter/ControlCenterCard.js';
import ControlCenterChart from './components/ControlCenter/ControlCenterChart.js';
import ControlCenterIPsTable from './components/ControlCenter/ControlCenterIPsTable.js';
import ControlCenterProjectsTable from './components/ControlCenter/ControlCenterProjectsTable.js';
import ControlCenterDomainsTable from './components/ControlCenter/ControlCenterDomainsTable.js';
import ControlCenterMailboxesTable from './components/ControlCenter/ControlCenterMailboxesTable.js';

import ConnectedIpsModalAddIp from './components/ConnectedIPs/ConnectedIpsModalAddIp.js';
import ProjectsModalAdd from './components/Projects/ProjectsModalAdd.js';
import DomainsModalAdd from './components/Domains/DomainsModalAdd.js';
import MailboxesModalAdd from './components/Mailboxes/MailboxesModalAdd.js';
import { Toaster } from 'react-hot-toast';


const ControlCenter = () => {
  const [cardData, setCardData] = useState([
    {
      label: 'Sent',
      data: '38,101',
      change: '-9506',
      status: 'decrease'
    },
    {
      label: 'Open Rate',
      data: '31.0%',
      change: '+7.0%',
      status: 'increase'
    },
    {
      label: 'Bounce Rate',
      data: '1.4%',
      change: '+0.9%',
      status: 'increase'
    },
    {
      label: 'Spam Complaints',
      data: '0.5%',
      change: '-87.0%',
      status: 'decrease'
    },
  ]);
  const [reloadKey, setReloadKey] = useState(0);

  const triggerReload = useCallback(() => {
    setReloadKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <div className="p-6 font-sans">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Control Center</h1>
      <h2 className="text-xl font-bold text-gray-600 mb-6">Overall Overview</h2>
      <div className="flex justify-between mb-12 space-x-4">
        {/* {cardData.map((item, index) => (
          index === 0 ? (
            <div key={index} className="flex-1 p-4 bg-white shadow rounded-md">
              <div className="text-lg font-semibold text-gray-700">{item.label}</div>
              <div className="text-2xl font-bold text-gray-800">{item.data}</div>
            </div>
          ) : (
            <div key={index} className="flex-1 p-4 bg-white shadow rounded-md placeholder-parent">
              <div class="placeholder-overlay rounded-md">Coming Soon</div>
              <div className="text-lg font-semibold text-gray-700">{item.label}</div>
              <div className="text-2xl font-bold text-gray-800">{item.data}</div>
            </div>
          )
        ))} */}
        {cardData.map((item, index) => (
          <div key={index} className="flex-1 p-4 bg-white shadow rounded-md placeholder-parent">
            <div className="placeholder-overlay rounded-md">Coming Soon</div>
            <div className="text-lg font-semibold text-gray-700">{item.label}</div>
            <div className="text-2xl font-bold text-gray-800">{item.data}</div>
          </div>
        ))}
      </div>
      <div className="mb-12">
        <div className="px-10 py-5 bg-white shadow rounded-md placeholder-parent">
          <div class="placeholder-overlay rounded-md">Coming Soon</div>
          <ControlCenterChart />
        </div>
      </div>
      <div key={reloadKey} className="mt-12">
        <div className="flex justify-between px-10">
          <h1 className="font-bold text-lg">IPs</h1>
          <ConnectedIpsModalAddIp reload={triggerReload} reloadKey={reloadKey} />
        </div>
        <div className="px-10 py-5 bg-white shadow rounded-md">
          <ControlCenterIPsTable reload={triggerReload} reloadKey={reloadKey} />
        </div>

        <div className="flex justify-between px-10 mt-8">
          <h1 className="font-bold text-lg">Projects</h1>
          <ProjectsModalAdd reload={triggerReload}></ProjectsModalAdd>
        </div>
        <div className="px-10 py-5 bg-white shadow rounded-md">
          <ControlCenterProjectsTable reload={triggerReload} reloadKey={reloadKey} />
        </div>

        <div className="flex justify-between px-10 mt-8">
          <h1 className="font-bold text-lg">Domains</h1>
          <DomainsModalAdd reload={triggerReload}/>
        </div>
        <div className="px-10 py-5 bg-white shadow rounded-md">
          <ControlCenterDomainsTable reload={triggerReload} reloadKey={reloadKey} />
        </div>

        <div className="flex justify-between px-10 mt-8">
          <h1 className="font-bold text-lg">Mailboxes</h1>
          <MailboxesModalAdd reload={triggerReload}/>
        </div>
        <div className="px-10 py-5 bg-white shadow rounded-md">
          <ControlCenterMailboxesTable reload={triggerReload} reloadKey={reloadKey} />
        </div>
      </div>
      <Toaster/>
    </div>
  );
}

export default ControlCenter;
