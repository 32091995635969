import React from 'react';
import Table from '../parents/Table.js';

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const ControlCenterIPsTable = () => {
  const url = DJANGO_HOST + 'user_relay_server/';

  const headers = [
    { name: "IPS", available: true },
    { name: "Provider", available: true },
    { name: "Projects", available: true },
    { name: "Domains", available: true },
    { name: "Mailboxes", available: true },
    { name: "Sent Emails", available: true }
    // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints, Status, and Health from headers
  ];

  const handlePrefixAction = (option, id) => {};

  const handleSuffixAction = (option, id) => {};

  const renderPrefix = (row) => {};

  const renderSuffix = (row) => {};
  
  return (
    <Table
      url={url}
      title={"IPs"}
      headers={headers}
      preffixColumn={false}
      suffixColumn={false}
      renderPrefix={renderPrefix}
      renderSuffix={renderSuffix}
      excludeFields={['relay_server_id', 'status', 'health', 'open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}  // Exclude unwanted fields
    />
  );
};

export default ControlCenterIPsTable;
