import React from 'react';
import Table from '../parents/Table.js';

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const ControlCenterDomainsTable = (reloadKey) => {
  const url = DJANGO_HOST + 'domain/';

  const headers = [
    { name: "Domain", available: true },
    { name: "Mailboxes", available: true },
    { name: "Sent Emails", available: true },
    { name: "Status", available: true },
    { name: "Health", available: false }
    // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints
  ];

  const handlePrefixAction = (option, id) => {};
  const handleSuffixAction = (option, id) => {};
  const renderPrefix = (row) => {};
  const renderSuffix = (row) => {};
  
  return (
    <Table
      url={url}
      title={"Domains"}
      headers={headers}
      preffixColumn={false}
      suffixColumn={false}
      renderPrefix={renderPrefix}
      renderSuffix={renderSuffix}
      reloadKey={reloadKey}
      excludeFields={['open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}  // Exclude unwanted fields
    />
  );
};

export default ControlCenterDomainsTable;
