import React, { useState, useEffect } from "react";
import { useAuth } from '@clerk/clerk-react';
import { useParams } from 'react-router-dom';
import Input from "../parents/Input.js";
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";
import Dropdown from "../parents/Dropdown.js";
import { useUser } from '../../../usercontext';
import axios from 'axios';

const defaultStyles = {
    Title: {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton: {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    },
    Select: {
        width: '100%',
        padding: '0.5rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '1rem',
        boxSizing: 'border-box',
    }
};

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const MailboxesModalAdd = ({ reload }) => {
    const { project_slug, domain_slug } = useParams();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [autoReply, setAutoReply] = useState("");
    const [selectedIp, setSelectedIp] = useState("");
    const [selectedIpId, setSelectedIpId] = useState("");
    const [ips, setIps] = useState({});
    const [selectedProject, setSelectedProject] = useState("");
    const [projects, setProjects] = useState({});
    const [selectedDomain, setSelectedDomain] = useState("");
    const [domains, setDomains] = useState({});
    const [amount, setAmount] = useState(1);
    const [domainCart, setDomainCart] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { getToken } = useAuth();
    const { userInfo, isLoading: userLoading, subscriptionDetails, isLoading: subscriptionLoading } = useUser();
    const [isAuto, setIsAuto] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const token = await getToken();
            if (!token) return;

            try {
                // Fetch IPs
                const ipResponse = await fetch(DJANGO_HOST + 'user_relay_server/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const ipData = await ipResponse.json();
                const ipsData = {};
                ipData.forEach(element => {
                    const name = element.relay_server_name;
                    if (name) {
                        ipsData[name] = element;
                    }
                });
                setIps(ipsData);
                if (Object.keys(ipsData).length > 0) {
                    const firstIpName = Object.keys(ipsData)[0];
                    setSelectedIp(firstIpName);
                    setSelectedIpId(ipsData[firstIpName].relay_server_id);
                }

                // Fetch projects
                const projectResponse = await fetch(DJANGO_HOST + 'projects/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const projectData = await projectResponse.json();
                const projectsData = {};
                projectData.forEach(element => {
                    projectsData[element.name] = element.id;
                });
                setProjects(projectsData);
                if (project_slug && projectsData[project_slug]) {
                    setSelectedProject(projectsData[project_slug]);
                } else if (Object.keys(projectsData).length > 0) {
                    setSelectedProject(Object.values(projectsData)[0]);
                }

                // Fetch domains
                const domainResponse = await fetch(DJANGO_HOST + 'domain/', {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const domainData = await domainResponse.json();
                const domainsData = {};
                domainData.forEach(element => {
                    domainsData[element.name] = element.id;
                });
                setDomains(domainsData);
                if (domain_slug && domainsData[domain_slug]) {
                    setSelectedDomain(domainsData[domain_slug]);
                } else if (Object.keys(domainsData).length > 0) {
                    setSelectedDomain(Object.values(domainsData)[0]);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                setErrors(prevErrors => [...prevErrors, "Failed to load necessary data"]);
            }

            setIsLoading(false);
        };
        fetchData();
    }, [getToken, DJANGO_HOST, project_slug, domain_slug]);

    const handleIpChange = (e) => {
        const ipName = e.target.value;
        const ipObject = ips[ipName];
        if (ipObject) {
            setSelectedIp(ipName);
            setSelectedIpId(ipObject.relay_server_id);
        }
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const handleProjectChange = (e) => {
        setSelectedProject(e.target.value);
    };

    const handleDomainChange = (e) => {
        setSelectedDomain(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'autoReply':
                setAutoReply(value);
                break;
            case 'amount':
                setAmount(parseInt(value));
                break;
            default:
                break;
        }
    };

    const validateForm = () => {
        const newErrors = [];
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!firstName) newErrors.push("First name is required");
        if (!lastName) newErrors.push("Last name is required");
        if (isAuto) {
            if (!amount || amount <= 0) newErrors.push("Amount must be greater than 0");
        } else {
            if (!username) {
                newErrors.push("Username is required");
            } else if (!emailRegex.test(`${username}@${Object.keys(domains).find(key => domains[key] === selectedDomain)}`)) {
                newErrors.push("Invalid email format");
            }
        }
        if (!password) {
            newErrors.push("Password is required");
        } else if (!validatePassword(password)) {
            newErrors.push("Password must be at least 8 characters long");
        }
        if (!selectedProject) newErrors.push("Please select a project");
        if (!selectedDomain) newErrors.push("Please select a domain");
        if (!selectedIpId) newErrors.push("Please select an IP");

        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const addToDomainCart = (mailbox) => {
        setDomainCart(prevCart => {
            const updatedCart = [...prevCart, mailbox];
            console.log("Updated Domain Cart:", updatedCart);
            return updatedCart;
        });
    };

    const handleSubmit = () => {
        if (!validateForm()) {
            console.log("Form is invalid", errors);
            return;
        }

        const domainName = Object.keys(domains).find(key => domains[key].toString() === selectedDomain.toString());
        if (!domainName) {
            setErrors(prevErrors => [...prevErrors, "Invalid domain selected"]);
            return;
        }

        const passwordToSend = password ? password : ""; // Make sure password is passed, even if empty
        console.log("Password to send:", passwordToSend); // Log password being passed

        if (isAuto) {
            const emails = generateEmails(firstName, lastName, domainName, amount);
            emails.forEach(email => {
                const mailbox = {
                    email,
                    firstName,
                    lastName,
                    domain: domainName,
                    password: passwordToSend,  // Pass the password
                    autoReply,
                    project: selectedProject,
                    relay_server: selectedIpId,
                };
                addToDomainCart(mailbox);
            });
        } else {
            const mailbox = {
                email: username + '@' + domainName,
                firstName,
                lastName,
                domain: domainName,
                password: passwordToSend,  // Pass the password
                autoReply,
                project: selectedProject,
                relay_server: selectedIpId,
            };
            addToDomainCart(mailbox);
        }
    };

    const updateMailboxCount = async () => {
        if (!userInfo || !subscriptionDetails) {
            alert('User info or subscriptions are not loaded yet.');
            return;
        }

        const baseSubscription = subscriptionDetails.find(sub => sub.plan_name === 'Base-Subscription-USD-Monthly');
        if (!baseSubscription) {
            alert('Base Subscription not found.');
            return;
        }

        const customer_id = baseSubscription.customer_id;
        const addon_id = 'Mailbox-USD-Monthly';
        const quantity = baseSubscription.total_mailboxes + amount;

        if (!customer_id) {
            alert('Customer ID not found.');
            return;
        }

        try {
            const csrfToken = getCookie('csrftoken');
            const token = await getToken();  // Await the token retrieval

            const response = await axios.post(`${DJANGO_HOST}update_mailbox_addon/`, {
                customer_id,
                addon_id,
                quantity,
                prorate: true
            }, {
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.message) {
                alert('Mailbox count updated successfully');
            } else {
                alert('Failed to update mailbox count');
            }
        } catch (error) {
            console.error('Error updating mailbox count:', error);
            if (error.response && error.response.data) {
                alert(`Error updating mailbox count: ${error.response.data.error}`);
            } else {
                alert('Error updating mailbox count');
            }
        }
    };

    const submitMailboxes = async () => {
        try {
            console.log("Submitting mailboxes...");

            const token = await getToken();  // Await the token retrieval
            const csrfToken = getCookie('csrftoken');

            const response = await fetch(DJANGO_HOST + 'mailbox/bulk/', {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    emails: domainCart.map(mailbox => mailbox.email),
                    project: selectedProject,
                    domain: selectedDomain,
                    relay_server: selectedIpId,
                    first_name: firstName,
                    last_name: lastName,
                    password: password, // Ensure password is being sent
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error submitting mailboxes:", errorData);
                setErrors([errorData.message || "An error occurred while submitting mailboxes."]);
                return;
            }

            const data = await response.json();
            console.log("Mailboxes submitted successfully:", data);
            setDomainCart([]);
            alert('Mailboxes submitted successfully');

            // Reload the entire page to refresh user info and mailbox count
            window.location.reload();  // This will force the browser to reload the entire page

        } catch (error) {
            console.error("Error submitting mailboxes:", error);
            setErrors([error.message || "An error occurred while submitting mailboxes."]);
        }
    };


    const handlePurchase = async (closeModal) => {
        const token = await getToken();

        if (userLoading || subscriptionLoading) {
            alert('User info or subscriptions are not loaded yet.');
            return;
        }

        try {
            const currentMailboxes = userInfo.mailboxes;
            const chargebeeMailboxes = subscriptionDetails
                .find(subscription => subscription.plan_name === 'Base-Subscription-USD-Monthly')
                ?.total_mailboxes;

            const totalMailboxesToAdd = domainCart.length;
            const availableMailboxes = chargebeeMailboxes - currentMailboxes;

            console.log("Current mailboxes in DB:", currentMailboxes);
            console.log("Mailboxes in Chargebee:", chargebeeMailboxes);
            console.log("Mailboxes to add:", totalMailboxesToAdd);

            if (totalMailboxesToAdd <= availableMailboxes) {
                console.log("Adding mailboxes without purchasing additional ones.");
                await submitMailboxesWithoutPurchase();
                await submitMailboxes();
            } else {
                const mailboxesToPurchase = totalMailboxesToAdd - availableMailboxes;
                console.log(`Automatically purchasing ${mailboxesToPurchase} additional mailboxes.`);
                await purchaseAdditionalMailboxes(mailboxesToPurchase);
                await submitMailboxes();
            }

            closeModal();
        } catch (error) {
            console.error("Error purchasing mailboxes:", error);
            setErrors([error.message || "An error occurred while purchasing mailboxes."]);
        }
    };

    const submitMailboxesWithoutPurchase = async () => {
        try {
            console.log("Mailboxes added successfully without purchasing extra.");
        } catch (error) {
            console.error("Error adding mailboxes:", error);
        }
    };

    const purchaseAdditionalMailboxes = async (mailboxesToPurchase) => {
        try {
            const baseSubscription = subscriptionDetails.find(sub => sub.plan_name === 'Base-Subscription-USD-Monthly');
            const customer_id = baseSubscription.customer_id;
            const addon_id = 'Mailbox-USD-Monthly';

            // Chargebee API to update subscription with prorated charges
            const response = await axios.post(`${DJANGO_HOST}update_mailbox_addon/`, {
                customer_id,
                addon_id,
                quantity: baseSubscription.total_mailboxes + mailboxesToPurchase,
                prorate: true // Prorate charge for the remainder of the cycle
            }, {
                headers: {
                    'X-CSRFToken': getCookie('csrftoken'),
                    'Authorization': `Bearer ${await getToken()}`,
                    'Content-Type': 'application/json'
                }
            });

            console.log("Purchased additional mailboxes with prorated charge:", response.data);
        } catch (error) {
            console.error("Error purchasing additional mailboxes:", error);
        }
    };


    const generateEmails = (firstName, lastName, domain, amount) => {
        const emailPatterns = [
            `${firstName}`,
            `${firstName}.${lastName[0]}`,
            `${firstName}.${lastName}`,
            `${firstName[0]}.${lastName}`,
            `${firstName}${lastName[0]}`,
            `${firstName[0]}${lastName}`,
        ];

        let emails = [];
        for (let i = 0; i < amount; i++) {
            emails.push(`${emailPatterns[i % emailPatterns.length]}@${domain}`);
        }
        return emails;
    };

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Buy Mailbox</label>
        );
    };

    const renderBody = () => {
        return isLoading ? (
            <div>Loading...</div>
        ) : (
            <>
                <div className="flex gap-4 mb-4">
                    <div className="flex items-center">
                        <input
                            id="auto-generate"
                            type="radio"
                            name="generationType"
                            checked={isAuto}
                            onChange={() => setIsAuto(true)}
                            className="form-radio"
                        />
                        <label htmlFor="auto-generate" className="ml-2 font-bold">Auto Generate Mailboxes</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            id="manual-create"
                            type="radio"
                            name="generationType"
                            checked={!isAuto}
                            onChange={() => setIsAuto(false)}
                            className="form-radio"
                        />
                        <label htmlFor="manual-create" className="ml-2 font-bold">Manual Mailbox creation</label>
                    </div>
                </div>
                <div className="h-64 overflow-y-auto">
                    {isAuto ? (
                        <>
                            <div className="pb-4">
                                <label className="font-semibold">Project</label>
                                <Dropdown
                                    value={selectedProject}
                                    onChange={handleProjectChange}
                                    label={"Select a Project"}
                                    values={{ ...projects, "": "Select a Project" }}
                                    disabled={!!project_slug}
                                />
                            </div>
                            <div className="pb-4">
                                <label className="font-semibold">Domain</label>
                                <Dropdown
                                    value={selectedDomain}
                                    onChange={handleDomainChange}
                                    label={"Select a Domain"}
                                    values={{ ...domains, "": "Select a Domain" }}
                                    disabled={!!domain_slug}
                                />
                            </div>
                            <div className="pb-4 relative">
                                <label className="font-semibold">IP</label>
                                <select
                                    value={selectedIp}
                                    onChange={handleIpChange}
                                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option value="" disabled>Select an IP</option>
                                    {Object.keys(ips).map((ipName, index) => (
                                        <option key={index} value={ipName}>
                                            {ipName}
                                        </option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 pb-4">
                                <div>
                                    <label className="font-semibold">Sender First Name</label>
                                    <Input name={"firstName"} placeholder={"First Name"} onChange={handleInputChange} />
                                </div>
                                <div>
                                    <label className="font-semibold">Sender Last Name</label>
                                    <Input name={"lastName"} placeholder={"Last Name"} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="pb-4">
                                <label className="font-semibold">How many Mailboxes would you like?</label>
                                <div className="flex items-center mt-2">
                                    <input
                                        name="amount"
                                        type="range"
                                        min="1"
                                        max="5"
                                        value={amount}
                                        onChange={handleInputChange}
                                        className="slider w-full"
                                    />
                                    <span className="ml-4">{amount}</span>
                                </div>
                            </div>
                            <div className="pb-4">
                                <label className="font-semibold">Set the Password (min 8 characters)</label>
                                <Input 
                                    name={"password"} 
                                    type="password"
                                    placeholder={"Set the Password"} 
                                    onChange={handleInputChange} 
                                />
                            </div>
                            <div className="flex justify-center">
                                <Button action={handleSubmit} style={defaultStyles.AcceptButton} label="Generate & Add" />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="pb-4">
                                <label className="font-semibold">Project</label>
                                <Dropdown
                                    value={selectedProject}
                                    onChange={handleProjectChange}
                                    label={"Select a Project"}
                                    values={{ ...projects, "": "Select a Project" }}
                                    disabled={!!project_slug}
                                />
                            </div>
                            <div className="pb-4">
                                <label className="font-semibold">Domain</label>
                                <Dropdown
                                    value={selectedDomain}
                                    onChange={handleDomainChange}
                                    label={"Select a Domain"}
                                    values={{ ...domains, "": "Select a Domain" }}
                                    disabled={!!domain_slug}
                                />
                            </div>
                            <div className="pb-4 relative">
                                <label className="font-semibold">IP</label>
                                <select
                                    value={selectedIp}
                                    onChange={handleIpChange}
                                    className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option value="" disabled>Select an IP</option>
                                    {Object.keys(ips).map((ipName, index) => (
                                        <option key={index} value={ipName}>
                                            {ipName}
                                        </option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 pb-4">
                                <div>
                                    <label className="font-semibold">Sender First Name</label>
                                    <Input name={"firstName"} placeholder={"First Name"} onChange={handleInputChange} />
                                </div>
                                <div>
                                    <label className="font-semibold">Sender Last Name</label>
                                    <Input name={"lastName"} placeholder={"Last Name"} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="pb-4">
                                <label className="font-semibold">Type Username</label>
                                <Input name={"username"} placeholder={"Type Username"} onChange={handleInputChange} />
                            </div>
                            <div className="pb-4">
                                <label className="font-semibold">Set the Password</label>
                                <Input name={"password"} placeholder={"Set the Password"} onChange={handleInputChange} />
                            </div>
                            <div className="flex justify-center">
                                <Button action={handleSubmit} style={defaultStyles.AcceptButton} label="Add" />
                            </div>
                        </>
                    )}
                </div>
                <div className="py-4">
                    <h3 className="font-bold">Domain Cart</h3>
                    <ul>
                        {domainCart.map((mailbox, index) => (
                            <li key={index}>{mailbox.email}</li>
                        ))}
                    </ul>
                </div>
            </>
        );
    };

    const renderOptions = (closeModal) => {
        return (
            <>
                <Button action={closeModal} style={defaultStyles.CancelButton} label="Cancel" />
                <Button action={() => handlePurchase(closeModal)} style={defaultStyles.AcceptButton} label="Purchase" />
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
            text={"Add Mailbox"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default MailboxesModalAdd;