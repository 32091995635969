import React, { useState, useCallback } from "react";
import { useAuth } from '@clerk/clerk-react';
import Modal from '../parents/Modal.js';
import Button from "../parents/Button.js";

const defaultStyles = {
    Title : {
        color: '#030303',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: '24px',
    },
    AcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#82e8ed',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    DisabledAcceptButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#4ec5ca',
        color: '#000000',
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '18px',
        outline: 'none',
    },
    CancelButton : {
        padding: '1.5rem 3rem',
        border: '0',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: '0px 2px 8px rgba(0,0,0,0.16)',
        backgroundColor: '#f6f6f6',
        color: '#000000',
        fontSize: '1rem',
        lineHeight: '18px',
        outline: 'none',
    }
}

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const DomainsModalDelete = ({ name, id, reload }) => {
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const { getToken } = useAuth();

    const renderTitle = () => {
        return (
            <label style={defaultStyles.Title}>Are you sure you want to delete - {name}</label>
        );
    };

    const renderBody = () => {
        return (
            <p className="text-justify">
                By deleting this domain, all related mailboxes will be deleted and no emails will be sent from them.
            </p>
        );
    };

    const renderOptions = (closeModal) => {
        const action = async () => {
            const url = DJANGO_HOST + "domain/" + id + "/";
            setLoading(true);

            try {
                const token = await getToken();

                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                });

                if (response.status === 204) {
                    console.log('Domain deleted successfully');
                    closeModal();
                    if (typeof reload === 'function') {
                        reload();
                    } else {
                        console.error('reload is not a function:', reload);
                    }
                } else {
                    console.error('Error deleting domain:', response.status);
                    setErrors(['An error occurred while deleting the domain.']);
                }
            } catch (error) {
                console.error('Error deleting domain:', error);
                setErrors(['An error occurred while deleting the domain.']);
            } finally {
                setLoading(false);
            }
        };

        return (
            <>
                <Button 
                    action={closeModal} 
                    style={defaultStyles.CancelButton} 
                    label="Close"
                />
                <Button 
                    action={action} 
                    style={loading ? defaultStyles.DisabledAcceptButton : defaultStyles.AcceptButton} 
                    label={loading ? "Processing..." : "Yes, Delete it"} 
                    disabled={loading}
                />
            </>
        );
    };

    const renderErrors = () => {
        return (
            <ul>
                {errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                        - {error}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Modal
            icon={"delete"}
            tooltip={"Delete"}
            renderTitle={renderTitle}
            renderBody={renderBody}
            renderOptions={renderOptions}
            renderErrors={renderErrors}
        />
    );
};

export default DomainsModalDelete;