import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Table from '../parents/Table.js';
import DomainsModalDNS from './DomainsModalDNS.js';
import DomainsModalDelete from './DomainsModalDelete.js';
import DomainsModalDisable from './DomainsModalDisable.js';
import useCachedToken from '../../../useCachedToken';
import { useDomainVerification } from './DomainsVerificationContext';

const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

const generateSlug = (text) => {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/&/g, '-and-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-');
};

const DomainsTable = ({ reload }) => {
    const { relay_server_slug, project_slug } = useParams();
    const [domains, setDomains] = useState([]);
    const token = useCachedToken();
    const { verifyingDomains, domainStatuses } = useDomainVerification();
    const url = relay_server_slug && project_slug
        ? `${DJANGO_HOST}domain/?relay_server_slug=${relay_server_slug}&project_slug=${project_slug}`
        : `${DJANGO_HOST}domain/`;

    const headers = [
        { name: "Domain", available: true },
        { name: "Mailboxes", available: true },
        { name: "Sent Emails", available: true },
        { name: "Status", available: true },
        { name: "Health", available: false }
        // Removed Open Rate, Reply Rate, Bounce Rate, Spam Complaints
    ];

    const fetchDomains = async () => {
        try {
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setDomains(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching domains:', error);
            setDomains([]);
        }
    };

    useEffect(() => {
        if (token) {
            fetchDomains();
        }
    }, [url, relay_server_slug, project_slug, token]);

    const renderDomainLink = (cell, row) => {
        const domainSlug = generateSlug(row.name);
        return (
            <td className="py-3 px-6 whitespace-normal break-words">
                <Link to={`/navigate/${row.relay_server_slug}/${row.project_slug}/${domainSlug}/`}>
                    {cell}
                </Link>
            </td>
        );
    };

    const renderStatus = (cell, row) => {
        const status = domainStatuses[row.id] || cell;
        if (verifyingDomains[row.id]) {
            return (
                <td className="py-3 px-6">
                    <span className="text-yellow-500">Verifying<span className="animate-pulse">...</span></span>
                </td>
            );
        }
        return (
            <td className="py-3 px-6">
                {status === 'Verified' ? 
                    <span className="text-green-500">Verified</span> : 
                    <span className="text-red-500">Not Verified</span>
                }
            </td>
        );
    };

    const renderPrefix = (row) => {
        const isChecked = row.active === "Active" ? true : false;
        return (
            <td className="py-3 px-6 flex">
                <DomainsModalDisable isChecked={isChecked}></DomainsModalDisable>
            </td>
        );
    };

    const renderSuffix = (row) => {
        return (
            <td className="py-3 px-2 flex whitespace-normal break-words">
                <DomainsModalDNS reload={reload} name={row.name} id={row.id} />
                <DomainsModalDelete reload={reload} name={row.name} id={row.id} />
            </td>
        );
    };

    const processedDomains = domains.map(({ project_slug, relay_server_slug, ...rest }) => rest);

    return (
        <div>
            <Table
                url={url}
                title={"Domains"}
                headers={headers}
                data={processedDomains}
                preffixColumn={null}
                suffixColumn={"Actions"}
                renderPrefix={renderPrefix}
                renderSuffix={renderSuffix}
                customRenderers={{ 
                    "name": renderDomainLink,
                    "status": renderStatus
                }}
                excludeFields={['open_rate', 'reply_rate', 'bounce_rate', 'spam_complaints']}  // Exclude unwanted fields
            />
        </div>
    );
};

export default DomainsTable;
