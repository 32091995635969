import React from 'react';
import Table from '../parents/Table.js';

const DomainsModalDNSTable = ({id}) => {
    const DJANGO_HOST = process.env.REACT_APP_DJANGO_HOST;

    const url = DJANGO_HOST + 'domain/' + id + "/?records=true";

    const headers = [
      { name: "Host Name", available: true },
      { name: "Record Type", available: true },
      { name: "Value", available: true },
      { name: "Status", available: true }
    ];
  
    const handlePrefixAction = (option, id) => {};

    const handleSuffixAction = (option, id) => {};

    const renderPrefix = (row) => {
      return (
        <td className="py-3 pl-6 text-left whitespace-nowrap">
          Lock check
        </td>
      );
    };

    const renderSuffix = (row) => {
        const suffixConfig = ['edit'];
    };

    return (
      <Table
        url={url}
        headers={headers}
        preffixColumn={false}
        suffixColumn={false}
        renderPrefix={renderPrefix}
        renderSuffix={renderSuffix}
        handlePrefixAction={handlePrefixAction}
        handleSuffixAction={handleSuffixAction}
      />
    );
  };
  
  export default DomainsModalDNSTable;