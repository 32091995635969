import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '@clerk/clerk-react';

const DomainVerificationContext = createContext();

export const useDomainVerification = () => useContext(DomainVerificationContext);

export const DomainVerificationProvider = ({ children }) => {
  const [verifyingDomains, setVerifyingDomains] = useState({});
  const [domainStatuses, setDomainStatuses] = useState({});
  const { getToken } = useAuth();

  // Load domain statuses from localStorage on initial render
  useEffect(() => {
    const savedStatuses = localStorage.getItem('domainStatuses');
    if (savedStatuses) {
      setDomainStatuses(JSON.parse(savedStatuses));
    }
  }, []);

  const verifyDomain = useCallback(async (id) => {
    const url = `${process.env.REACT_APP_DJANGO_HOST}domain/${id}/`;
    const token = await getToken();
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Requested-With': 'XMLHttpRequest',
      }
    });
    return response.status === 200;
  }, []);

  const startVerification = useCallback((id) => {
    setVerifyingDomains(prev => ({ ...prev, [id]: true }));
  }, []);

  const endVerification = useCallback((id, name, success) => {
    setVerifyingDomains(prev => ({ ...prev, [id]: false }));
    if (success) {
      toast.success(`Domain ${name} verified successfully!`);
    } else {
      toast.error(`Failed to verify domain ${name}. Please check your DNS settings and try again later.`);
    }
  }, []);

  const updateDomainStatus = useCallback((id, status) => {
    setDomainStatuses(prev => {
      const newStatuses = { ...prev, [id]: status };
      localStorage.setItem('domainStatuses', JSON.stringify(newStatuses));
      return newStatuses;
    });
  }, []);

  const retryVerification = useCallback(async (id, name, token) => {
    startVerification(id);
    const maxRetries = 10;
    const retryInterval = 180000; // 3 minutes
    for (let i = 0; i < maxRetries; i++) {
      console.log(`Verification attempt ${i + 1}/${maxRetries} for domain ${name}`);
      try {
        const success = await verifyDomain(id, token);
        if (success) {
          updateDomainStatus(id, 'Verified');
          endVerification(id, name, true);
          return true;
        }
      } catch (error) {
        console.error('Error verifying domain:', error);
      }
      if (i < maxRetries - 1) {
        console.log(`Waiting ${retryInterval / 1000} seconds before next attempt...`);
        await new Promise(resolve => setTimeout(resolve, retryInterval));
      }
    }
    updateDomainStatus(id, 'Not Verified');
    endVerification(id, name, false);
    return false;
  }, [verifyDomain, startVerification, endVerification, updateDomainStatus]);

  const value = {
    verifyingDomains,
    domainStatuses,
    startVerification,
    endVerification,
    retryVerification,
    updateDomainStatus
  };

  return (
    <DomainVerificationContext.Provider value={value}>
      {children}
    </DomainVerificationContext.Provider>
  );
};